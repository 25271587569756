import React from 'react';
import { Router } from "@reach/router"

import Layout from '../components/layout';
import SEO from '../components/seo';
import BlogSection from '../components/sections/Blog'
import Post from '../components/Post'

const Blog = () => {
  return (
    <Layout>
      <SEO title='Blog'/>
      <Router basepath="/">
        <BlogSection path="/blog" />
        <Post path="/blog/:id" />
      </Router>
    </Layout>
  );
};

export default Blog;