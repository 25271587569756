import React, { useState, useEffect } from "react"
import styled from "styled-components"
import unified from "unified"
import parse from "remark-parse"
import remark2react from "remark-react"

import SEO from "../components/seo"
import Container from "./Container"
import { getPost } from "../services/api/posts"

const StyledContainer = styled(Container)`
  padding: 100px 15px;
  max-width: 800px;
`

const Post = ({ id }) => {
  const [post, setPost] = useState({})

  useEffect(() => {
    loadPost(id)
  }, [])

  useEffect(() => {
    if (typeof window !== "undefined") {
      const wow = require("wowjs")
      new wow.WOW({ live: false, mobile: false }).init()
    }
  })

  const loadPost = async id => {
    try {
      const result = await getPost(id)
      setPost(result || [])
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <StyledContainer>
      {post.title && <SEO title={post.title} />}
      <h1>{post.title}</h1>
      <img src={post.featured_images} alt={post.title} />
      {
        unified()
          .use(parse)
          .use(remark2react)
          .processSync(post.content).result
      }
    </StyledContainer>
  )
}

export default Post
